import React, {Component} from "react";
import {history} from "../../configs/history";

import "../../assets/styles/components/uiElements/breadcrumb.scss"

class Breadcrumbs extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {crumbs} = this.props
        return <div className={`breadcrumb-wrapper ${crumbs.length === 1 ? "absolute-wrapper" : ""}`}>
            {crumbs && !!crumbs.length && crumbs.map((item, index) =>
                <span key={index} className="breadcrumb-item">{index !== 0 && " / "}
                    <span className={`${index !== crumbs.length - 1 ? "link-item" : ""}`}
                          onClick={() => index !== crumbs.length - 1 && history.go(-(crumbs.length - index) + 1)}>{item?.name}
                </span>
                </span>
            )}
        </div>
    }
}

export default Breadcrumbs;