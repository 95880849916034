// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateCategory, UpdateCategoryById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";


class AddEditCategory extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            displayColorPicker: false,
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditCategory = this.addEditCategory.bind(this);
    }

    async componentDidMount() {
        const {isEditing} = this.state;
        const translationsData = {};

        if (isEditing) {
            const editingData = this.props.categoriesList.find(item => item.id === this.props.id);

            if (editingData) {

                editingData.translations.forEach(item => {
                    translationsData[item.language] = {
                        ...translationsData[item.language],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });

        }
    }

    addEditCategory() {
        this.setState({
            requestLoading: true
        });
        const {translations, isHidden} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: trData,
                };
                this.props.CreateCategory(reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    })
            } else {
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateCategoryById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Կատեգորիա' : 'Փոփոխել Կատեգորիան'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="category-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditCategory}/>
                </div>

            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateCategory,
    UpdateCategoryById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditCategory)
