import { request, _urlAbout } from "../api";
import { ABOUT_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetAboutData = () => {
    const requestData = {
        url: _urlAbout,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: data
                });
            })
    }
};


export const UpdateAboutData = (data) => {
    const requestData = {
        url: `${_urlAbout}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                return res.data
            })
    }
};


export const UpdateAboutItemData = (slug, data) => {
    const requestData = {
        url: `${_urlAbout}/block/${slug}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: ABOUT_CONSTS.SET_ABOUT_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/about')
                return res.data
            })
    }
};
