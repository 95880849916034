import React, {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Dropdown, Menu} from "antd";
import {history} from "../../configs/history";
import moment from "moment";

//import assets
import "../../assets/styles/media/mediaCard.scss";

//Import Images
import DownloadIcon from '../../assets/images/file-manager/ic_download.svg';
import EditIcon from '../../assets/images/file-manager/ic_edit.svg';
import RemoveIcon from '../../assets/images/file-manager/ic_remove.svg';
import CopyIcon from '../../assets/images/file-manager/ic_copy.svg';
import moreIcon from '../../assets/images/file-manager/ic_more.svg';
import markIcon from '../../assets/images/file-manager/check-mark.svg';
//import utils
import {resizeImage} from "../../utils/resizeImage";
import {generateFileMediaUrl, generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";
import {ACCEPT_AUDIO_TYPES, ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {getFileIcon} from "../../containers/media/utils/getFileIcon";

export const MediaFileCard = (props) => {
    const {
        item, isSelected = false, selectable = false, withEdit, customDelete,
        folderList, withCopy, draggable, disabled, selectedItems
    } = props;
    const [copyed, setCopyed] = useState(false);
    const [visible, setVisible] = useState(false);
    const isImage = ACCEPT_IMAGE_TYPES.includes(item.extension);
    const isAudio = ACCEPT_AUDIO_TYPES.includes(item.extension);
    const imgUrl = isImage ? resizeImage(generateImageMediaUrl(item.path))
        : getFileIcon(item.extension);

    function onDragEnd() {
        if (draggable) {
            props.dragFile(null);
        }
    }

    function onDragStart() {
        if (draggable) {
            props.dragFile(item);
        }
    }

    function setBtnVisible() {
        setVisible(!visible);
    }

    function downloadFile() {
        fetch(generateFileMediaUrl(item?.path))
            .then(response => {
                response.blob().then(blob => {
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = item?.name;
                    a.click();
                });
            });
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={downloadFile}>
                <img src={DownloadIcon} alt=""/>Download
            </Menu.Item>
            {!selectable && withEdit &&
            <Menu.Item onClick={() => history.push({
                pathname: `media/edit/${item.id}`,
                state: {
                    data: item,
                    folderList
                }
            })}>
                <img src={EditIcon} alt=""/>Edit
            </Menu.Item>}
            {!selectable && (customDelete || props.deleteMedia) &&
            <Menu.Item onClick={() => customDelete ? customDelete(item.id)
                : props.deleteMedia(item.id)}>
                <img src={RemoveIcon} alt=""/>Remove
            </Menu.Item>}
            {withCopy && !disabled && <Menu.Item>
                <CopyToClipboard text={generateFileMediaUrl(item.path, true)}
                                 onCopy={() => setCopyed(true)}>
                    <div onClick={e => {
                        e.stopPropagation()
                    }}>
                        <img src={CopyIcon} alt=""/>Copy
                    </div>
                </CopyToClipboard></Menu.Item>}
        </Menu>
    );
    let size = "-"
    if (item?.size >= 1024 && item?.size < 1048576) {
        size = (item?.size / 1024).toFixed(1) + " KB"
    }
    if (item?.size >= 1048576) {
        size = (item?.size / 1048576).toFixed(1) + " MB"
    }

    return props.listView ?
        <div
            className={`media-list-card ${disabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${selectedItems?.find(file => file?.id === item?.id) ? 'selected-card' : ''}`}
            key={item.id}
            draggable={draggable}
            onClick={(e) => selectedItems && props.selectCard(e, item)}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}>
            <span className={'image-wrapper'}
                  onClick={() => (selectable && !disabled) ? props.selectImage(item, isSelected) : null}
                  onDoubleClick={() => !disabled && props.onDoubleClick && props.onDoubleClick(item)}>
                <div className={'card-image'}>
                    <img className={`image ${isImage ? "img-bg" : ""}`} src={imgUrl} alt="file"/>
                </div>
            </span>
            <span className={'name'}>{item?.name}</span>
            <span className={'size'}>{size}</span>
            <span className={'extension'}>{item?.extension}</span>
            <span className={'date'}>{moment(item?.createdAt).format('DD/MM/YYYY HH:mm')}</span>
            {!selectable && !disabled && (withEdit || isAudio || customDelete || props.deleteMedia || withCopy) &&
            <Dropdown overlay={menu}
                      placement="bottomRight"
                      overlayClassName={"actions-dropdown"}
                      onVisibleChange={setBtnVisible}>
                <span className={`more-btn ${visible ? "visible" : ""} ${isImage ? "bg-white" : ""}`}>
                    <img src={moreIcon}
                         alt=""/>
                </span>
            </Dropdown>}
            <span className={`checked ${isSelected ? "show" : ''}`}>
                <img
                    src={markIcon}
                    alt="delete"
                />
            </span>
        </div>
        :
        <div
            className={`media-card ${disabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${selectedItems?.find(file => file?.id === item?.id) ? 'selected-card' : ''}`}
            key={item.id}
            draggable={draggable}
            onClick={(e) => selectedItems && props.selectCard(e, item)}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}>

            <div className={"media-card-wrapper"}>
                <div className={'image-wrapper'}
                     onClick={() => (selectable && !disabled) ? props.selectImage(item, isSelected) : null}
                     onDoubleClick={() => !disabled && props.onDoubleClick && props.onDoubleClick(item)}>
                    {!selectable && !disabled && (withEdit || isAudio || customDelete || props.deleteMedia || withCopy) &&
                    <Dropdown overlay={menu}
                              placement="bottomRight"
                              overlayClassName={"actions-dropdown"}
                              onVisibleChange={setBtnVisible}>
                        <div className={`more-btn ${visible ? "visible" : ""} ${isImage ? "bg-white" : ""}`}>
                            <img src={moreIcon}
                                 alt=""/>
                        </div>
                    </Dropdown>}

                    <div className={'card-image'}>
                        <img className={`image ${isImage ? "img-bg" : ""}`} src={imgUrl} alt="file"/>
                    </div>
                </div>
                <div className={'card-description'}>
                    <span className={'extension'}>{item.extension}</span>
                    <span className={'size'}>{size}</span>
                    <span className={'name'}>{item.name}</span>
                </div>
            </div>
            <div className={`checked ${isSelected ? "show" : ''}`}>
                <img
                    src={markIcon}
                    alt="delete"
                />
            </div>
        </div>

}
