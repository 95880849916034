import { combineReducers } from "redux";

// import reducers
import auth from "./auth";
import utils from "./utils";
import languages from "./languages";
import moderators from "./moderators";
import staticTexts from "./staticTexts";
import media from "./media";
import composite from "./composite";
import category from "./category";
import blog from "./blog";
import slider from "./slider";
import partner from "./partner";
import customPages from "./customPages";
import general from "./general";
import menu from "./menu";
import subscribers from "./subscribers";
import about from "./about";
import services from "./services";
import works from "./works";
import members from "./members";
import features from "./features";
import reservation from "./reservation";
import membersSections from "./membersSections";


export default combineReducers({
    auth,
    utils,
    languages,
    media,
    composite,
    moderators,
    staticTexts,
    category,
    blog,
    slider,
    partner,
    customPages,
    general,
    menu,
    subscribers,
    about,
    services,
    works,
    members,
    features,
    reservation,
    membersSections
});
