import { MEMBERS_SECTIONS_CONSTS } from "../constants";

export const initialState = {
    membersSections: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MEMBERS_SECTIONS_CONSTS.SET_MEMBERS_SECTIONS:
            return {
                ...state,
                membersSections: action.payload,
            };

        default:
            return state;
    }
}
