// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetSubscribers,
    DeleteSubscriberById,
} from "../../redux/actions";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {deleteItem} from "../../utils/helperFunctions";
import ItemsList from "../../components/ItemsList";
import moment from "moment";


class Subscribers extends Component {
    constructor(props) {
        super(props);

        this.deleteSubscriberItem = this.deleteSubscriberItem.bind(this);
    }

    componentDidMount() {
        this.props.GetSubscribers();
    }

    deleteSubscriberItem(id){
        this.props.DeleteSubscriberById(id).then(()=>{
            this.props.GetSubscribers();
        })
    }

    render() {
        let {subscribersList, requestLoading} = this.props;

        let itemListHeaderInfo = {
            generalInfo: ["Անվանում", "Ամսաթիվ"],
            largeItems: [],
            actions: ["delete"],
            status: false,
        };
        let itemListInfo = subscribersList?.map((subscriber) => {
            const {id,email, createdAt} = subscriber;
            return {
                id,
                values: [
                    email ?? '-',
                    moment(createdAt).format('DD/MM/YYYY HH:mm')
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteSubscriberById, 'Բաժանորդին', id)
            }
        });
        return <PageWrapper pageTitle={'Բաժանորդներ'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading} />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetSubscribers,
    DeleteSubscriberById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribers);
