// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
//import styles
import "../../assets/styles/containerStyles/about.scss";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";
import { MediaFileCard } from "../../components/media/MediaFileCard";

// Import utils
import {
  changeStateField,
  getInputValues,
  getPatchReqData,
  getTranslatableInputValues,
  getTranslationData,
  initTranslations,
  toggleStateField,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { UpdateMembersItemData, CreateMemberItem, GetMembersSectionsData } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";
import { ACCEPT_IMAGE_TYPES } from "../../constants/acceptedTypes";
import { history } from "../../configs/history";
import isURL from "validator/es/lib/isURL";

class AddEditMember extends Component {
  constructor(props) {
    super(props);
    this.translationsFields = ["name", "position", "description"];
    this.state = {
      fieldsData: {
        translations: initTranslations(
          this.translationsFields,
          props.activeLanguages
        ),
        mediaMain: undefined,
        lnUrl: "",
        email: "",
        phoneNumber: "",
        whatsApp: "",
        telegram: "",
        pageId: undefined,
        section: null
      },
      errors: {},
      errorsTabs: [],
      isEditing: !!props?.id,
      languageTab: props.mainLanguage,
      requestLoading: false,
    };
    this.updatedDataMap = new Map();
    this.currentData = null;
    this.toggleMediaModal = toggleStateField.bind(this, "mediaModalOpen");
    this.changeLanguageTab = changeStateField.bind(this, "languageTab");
    this.getInputValues = getInputValues.bind(this);
    this.getPatchReqData = getPatchReqData.bind(this);
    this.getTranslationValues = getTranslatableInputValues.bind(this);
    this.getMedia = this.getMedia.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.addEditMember = this.addEditMember.bind(this);
  }

  async componentDidMount() {
    this.props.GetMembersSectionsData();
    const { membersData, id, membersSections } = this.props;
    const { isEditing, fieldsData } = this.state;

    this.customPagesOptions = [];
    Array.isArray(this.props.customPagesList) &&
      this.props.customPagesList.forEach((item) => {
        const name =
          item?.translations?.find(
            (tr) => tr.language === this.props.mainLanguage
          )?.name || "";
        this.customPagesOptions.push({
          name: name,
          id: item?.id,
        });
      });
    if (isEditing && !id) {
      history.push("/members");
      return;
    }

    if (isEditing) {
      const translationsData = {};
      const editingData = membersData?.members.find(
        (member) => member.id === this.props.id
      );
      console.log(editingData, 'editingData');
      if (editingData) {
        Object.keys(fieldsData.translations).forEach((key) => {
          const trItem = editingData.translations.find(
            (item) => item.language === key
          );
          translationsData[key] = {
            ...fieldsData.translations[key],
            name: trItem?.name || "",
            position: trItem?.position || "",
            description: trItem?.description || "",
          };
        });
      }
      const initStateData = {
        translations: translationsData,
        lnUrl: editingData?.lnUrl || "",
        email: editingData?.email || "",
        phoneNumber: editingData?.phoneNumber || "",
        whatsApp: editingData?.whatsApp || "",
        telegram: editingData?.telegram || "",
        pageId: editingData?.pageId || "",
        mediaMain: editingData?.mediaMain,
        section: editingData?.section?.id,
      };
      this.currentData = JSON.parse(JSON.stringify(initStateData));
      this.setState({
        fieldsData: initStateData,
      });
    } else {
      this.setState((prev) => ({
        ...prev,
        currentData: {
          ...prev.currentData,
          section: membersSections?.[0]?._id
        },
        fieldsData: {
          ...prev.fieldsData,
          section: membersSections?.[0]?._id
        }
      }))
    }
  }

  getMedia(mediaArray) {
    const { fieldsData, errors, isEditing } = this.state;
    if (isEditing) {
      this.currentData?.mediaMain?.id !== mediaArray[0]?.id
        ? this.updatedDataMap.set("mediaMain", mediaArray[0]?.id)
        : this.updatedDataMap.delete("mediaMain");
    }
    this.setState({
      fieldsData: {
        ...fieldsData,
        mediaMain: mediaArray[0],
      },
      errors: {
        ...errors,
        mediaMain: false,
      },
    });
  }

  deleteMedia() {
    const { fieldsData, isEditing } = this.state;
    if (isEditing) {
      this.currentData?.mediaMain
        ? this.updatedDataMap.set("mediaMain", null)
        : this.updatedDataMap.delete("mediaMain");
    }
    this.setState({
      fieldsData: {
        ...fieldsData,
        mediaMain: null,
      },
    });
  }

  async addEditMember() {
    const { id } = this.props;
    await this.setState({
      requestLoading: true,
    });
    const {
      translations,
      mediaMain,
      lnUrl,
      pageId,
      email,
      phoneNumber,
      whatsApp,
      telegram,
      section
    } = this.state.fieldsData;
    const translationRequiredFields = ["name", "position", "description"];
    const validationTr = validateTranslations(
      translations,
      translationRequiredFields
    );
    let result = validationTr.result;
    const errors = { ...validationTr.errors };

    if (!mediaMain) {
      errors.mediaMain = true;
      result = false;
    }

    if (lnUrl && !isURL(lnUrl)) {
      errors.lnUrl = true;
      result = false;
    }
    if (!pageId) {
      errors.pageId = true;
    }
    if (result) {
      if (!this.state.isEditing) {
        const reqData = {
          mediaMain: mediaMain.id,
          translations: getTranslationData(translations),
        };

        if (lnUrl) reqData.lnUrl = lnUrl;
        if (pageId) reqData.pageId = pageId;
        if (email) reqData.email = email;
        if (phoneNumber) reqData.phoneNumber = phoneNumber;
        if (whatsApp) reqData.whatsApp = whatsApp;
        if (telegram) reqData.telegram = telegram;
        if (section) reqData.section = section;

        this.props.CreateMemberItem(reqData).finally(() => {
          this.setState({ requestLoading: false });
        });
      } else {
        const trData = getTranslationData(translations);
        const reqData = this.getPatchReqData(trData);
        this.props.UpdateMembersItemData(id, reqData).finally(() => {
          this.setState({ requestLoading: false });
        });
      }
    } else {
      this.setState({
        requestLoading: false,
        errors: errors,
        errorsTabs: validationTr.errorsTabs,
      });
    }
  }

  render() {
    const {
      fieldsData,
      errors,
      languageTab,
      mediaModalOpen,
      requestLoading,
      isEditing,
      errorsTabs,
    } = this.state;
    const initData = this.currentData?.translations[languageTab] || {};
    const currentData = fieldsData.translations[languageTab] || {};

    return (
      <PageWrapper
        pageTitle={!isEditing ? "Ավելացնել Թիմակից" : "Փոփոխել Թիմակին"}
      >
        <section className="slider-add-edit">
          <div className="top-side">
            <label>
              Նկար <span className={"required-badge"}>*</span>
            </label>
            <LinkButton
              title="Ընտրել մեդիադարանից"
              className={`media-select-btn ${errors.mediaMain ? "media-select-error" : ""
                }`}
              cb={this.toggleMediaModal}
            />
          </div>
          {fieldsData?.mediaMain && (
            <div className={"main-media"}>
              <MediaFileCard
                item={fieldsData?.mediaMain}
                customDelete={this.deleteMedia}
              />
            </div>
          )}

          <div className="info-wrapper">
            <InputGroup
              inputType="selectCustom"
              label="Բաժին"
              placeholder="Բաժին"
              name="section"
              required
              options={this.props?.membersSections?.map(el => ({ name: el.title, id: el._id }))}
              value={fieldsData?.section}
              initValue={this.currentData?.section}
              error={errors?.section}
              onChange={this.getInputValues}
            />
            <InputGroup
              inputType="input"
              type="text"
              label="Հղում"
              placeholder="Հղում"
              name="lnUrl"
              value={fieldsData?.lnUrl}
              initValue={this.currentData?.lnUrl}
              error={errors?.lnUrl}
              maxLength={100}
              onChange={this.getInputValues}
            />
            <InputGroup
              inputType="input"
              type="email"
              label="Email"
              placeholder="Email"
              name="email"
              value={fieldsData?.email}
              initValue={this.currentData?.email}
              error={errors?.email}
              maxLength={100}
              onChange={this.getInputValues}
            />
            <InputGroup
              inputType="input"
              type="phone"
              label="Հեռախոսահամար"
              placeholder="Հեռախոսահամար"
              name="phoneNumber"
              value={fieldsData?.phoneNumber}
              initValue={this.currentData?.phoneNumber}
              error={errors?.phoneNumber}
              maxLength={100}
              onChange={this.getInputValues}
            />
            <InputGroup
              inputType="input"
              type="text"
              label="Telegram"
              placeholder="Telegram"
              name="telegram"
              value={fieldsData?.telegram}
              initValue={this.currentData?.telegram}
              error={errors?.telegram}
              maxLength={100}
              onChange={this.getInputValues}
            />
            <InputGroup
              inputType="input"
              type="text"
              label="WhatsApp"
              placeholder="WhatsApp"
              name="whatsApp"
              value={fieldsData?.whatsApp}
              initValue={this.currentData?.whatsApp}
              error={errors?.whatsApp}
              maxLength={100}
              onChange={this.getInputValues}
            />
            {/*<InputGroup inputType="selectCustom"*/}
            {/*            name="pageId"*/}
            {/*            label={"Դինամիկ էջ"}*/}
            {/*            value={fieldsData.pageId}*/}
            {/*            initValue={this.currentData?.pageId}*/}
            {/*    // withClear={true}*/}
            {/*            error={errors['pageId']}*/}
            {/*            placeholder={'Դինամիկ էջ'}*/}
            {/*            onChange={this.getInputValues}*/}
            {/*            options={this.customPagesOptions}*/}
            {/*/>*/}
            <LanguageTabs
              changeLanguageTab={this.changeLanguageTab}
              errorsTabs={errorsTabs}
              activeTab={languageTab}
            />
            <InputGroup
              inputType="input"
              type="text"
              label="Անուն"
              placeholder="Անուն"
              name="name"
              value={currentData.name}
              initValue={initData.name}
              error={errors["name" + languageTab]}
              maxLength={100}
              required={true}
              onChange={this.getTranslationValues}
            />
            <InputGroup
              inputType="input"
              type="text"
              label="Պաշտոն"
              placeholder="Պաշտոն"
              name="position"
              value={currentData.position}
              initValue={initData.position}
              error={errors["position" + languageTab]}
              maxLength={1000}
              required={true}
              onChange={this.getTranslationValues}
            />
            <InputGroup
              inputType="textarea"
              type="text"
              label="Նկարագրություն"
              placeholder="Նկարագրություն"
              name="description"
              value={currentData.description}
              initValue={initData.description}
              error={errors["description" + languageTab]}
              required={true}
              onChange={this.getTranslationValues}
            />
          </div>

          <div className="flex-wrapper-right">
            <LinkButton
              title={!isEditing ? "Ավելացնել" : "Փոփոխել"}
              loading={requestLoading}
              disabled={isEditing && !this.updatedDataMap.size}
              cb={this.addEditMember}
            />
          </div>
        </section>
        <MediaSelectorModal
          isOpen={!!mediaModalOpen}
          acceptTypes={ACCEPT_IMAGE_TYPES}
          getMedia={this.getMedia}
          closeModal={this.toggleMediaModal}
        />
      </PageWrapper>
    );
  }
}

const mapDispatchToProps = {
  GetMembersSectionsData,
  CreateMemberItem,
  UpdateMembersItemData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMember);
