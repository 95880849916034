//--------------------------------------- Official-server --------------------------------------------
const ADMIN_URL = "https://app.askconsult.am/admin/api/v1";
export const HOST_MEDIA_URL = "https://app.askconsult.am/admin";

//--------------------------------------- 4Steps-server --------------------------------------------

// const ADMIN_URL =  "http://173.249.20.192:9700/api/v1";
// export const HOST_MEDIA_URL =  "http://173.249.20.192:9700";

export const _urlJwt = ADMIN_URL + "/jwt";
export const _urlMedia = ADMIN_URL + "/files";
export const _urlLanguages = ADMIN_URL + "/languages";
export const _urlContact = ADMIN_URL + "/contacts";
export const _urlModerator = ADMIN_URL + "/moderators";
export const _urlStaticTexts = ADMIN_URL + "/static-texts";
export const _urlCategories = ADMIN_URL + "/categories";
export const _urlSlider = ADMIN_URL + "/slider";
export const _urlBlog = ADMIN_URL + "/blogs";
export const _urlCustomPages = ADMIN_URL + "/custom-pages";
export const _urlMenu = ADMIN_URL + "/menu";
export const _urlSubscribers = ADMIN_URL + "/subscribers";
export const _urlAbout = ADMIN_URL + "/about";
export const _urlServices = ADMIN_URL + "/services";
export const _urlWorks = ADMIN_URL + "/works";
export const _urlPartner = ADMIN_URL + "/partners";
export const _urlMembers = ADMIN_URL + "/members";
export const _urlMembersSections = ADMIN_URL + "/members-sections";
export const _urlFeatures = ADMIN_URL + "/features";
export const _urlCallModel = ADMIN_URL + "/call-request";
