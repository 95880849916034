import React, {useState} from "react";
import defaultLogo from "../../assets/images/default_empty_logo.svg";
import {resizeImage} from "../../utils/resizeImage";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";

export function Image(props) {
    const {path, className = '', alt = ''} = props;
    const [brokenImage, setBrokenImage] = useState(false);

    const imageUrl = brokenImage ? defaultLogo : resizeImage(generateImageMediaUrl(path));

    return <img src={imageUrl}
                className={className}
                alt={alt}
                onError={() => {
                    // console.log('broken')
                    setBrokenImage(true)
                }}/>
}
