import { request, _urlMembersSections } from "../api";
import { MEMBERS_SECTIONS_CONSTS } from "../constants";

export const GetMembersSectionsData = () => {
    const requestData = {
        url: _urlMembersSections,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: MEMBERS_SECTIONS_CONSTS.SET_MEMBERS_SECTIONS,
                    payload: data
                });
            })
    }
};
