// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {history} from "../../configs/history";
import {Icon} from "antd";
import swal from "sweetalert";

// Import styles
import "../../assets/styles/containerStyles/media.scss";
import "../../assets/styles/media/media-header.scss";
import {ReactComponent as DeleteIcon} from "../../assets/images/ic_delete.svg";
import {ReactComponent as SearchIcon} from "../../assets/images/ic_search.svg";
import gridIcon from "../../assets/images/file-manager/grid.svg";
import listIcon from "../../assets/images/file-manager/list.svg";

// Import components
import {MediaFileCard} from "../../components/media/MediaFileCard";
import {MediaFolderCard} from "../../components/media/MediaFolderCard";
import {InputGroup} from "../../components/uiElements/inputGroup";
import PreviewModal from '../../components/media/PreviewModal';
import MediaUploaderWrapper from "../../components/media/AddMedia/MediaUploaderWrapper";
import AddMediaFolder from "../../components/media/AddFolder";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import {LinkButton} from "../../components/buttons/buttons";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {getFolderData} from "./utils/helperFunctions";
import {
    AddFolder,
    DeleteFolder,
    DeleteGalleryImage,
    GetMedia,
    toggleMediaView,
    UpdateFile
} from "../../redux/actions";
import {changeStateInputValue} from "../../utils/helperFunctions";
import {MaterialIcon} from "../../components/utils/Icon";


class Media extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accessList: {},
            searchValue: '',
            folderList: [],
            draggableFile: null,
            previewModalOpen: false,
            previewItem: null,
            selectedItems: []
        };

        this.getSearchValue = changeStateInputValue.bind(this);
        this.openFolder = this.openFolder.bind(this);
        this.deleteFolder = this.deleteFolder.bind(this);
        this.onDropToFolder = this.onDropToFolder.bind(this);
        this.dragFile = this.dragFile.bind(this);
        this.openPreview = this.openPreview.bind(this,);
        this.closePreview = this.closePreview.bind(this,);
        this.toggleSearchBtn = this.toggleSearchBtn.bind(this,);
        this.selectCard = this.selectCard.bind(this,);
        this.removeSelect = this.removeSelect.bind(this,);
        this.deleteMediaList = this.deleteMediaList.bind(this,);
    }

    componentDidMount() {
        const {location} = this.props;
        const folderList = location && location.state && location.state.folderList;
        // console.log('location.search',location.search)
        //location.search
        if (folderList) {
            this.setState({
                folderList
            });
            history.push({
                pathname: '/media',
                search: `?currentFolder=${folderList?.[folderList?.length - 1]?.name}`
            })
        } else if (location.search) {
            history.push({
                pathname: '/media',
            })
        }
        this.props.GetMedia();

        window.addEventListener('click', (e) => this.removeSelect(e));
        // return () => {
        // window.removeEventListener('click', (e) => this.removeSelect(e))
        // }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {location} = this.props;
        if (!location?.search && prevProps?.location?.search && this.state.folderList?.length) {
            this.setState({
                folderList: []
            });
        }

        if (location?.search !== prevProps?.location?.search) {
            this.setState({
                selectedItems: []
            });
        }
    }

    removeSelect(e) {
        if (this.state.selectedItems.length && !e.ctrlKey) {
            this.setState({
                selectedItems: []
            });
        }
    }

    deleteFile = (id) => {
        const {folderList} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել ֆայլը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteGalleryImage(id, pathList);
            }
        });
    };

    deleteFolder = (id) => {
        const {folderList} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել թղթապանակը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                this.props.DeleteFolder(id, pathList)
            }
        });
    };

    deleteMediaList = () => {
        const {folderList, selectedItems} = this.state;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);

        swal({
            title: "Զգուշացում!",
            text: "Ցանկանում եք ջնջել նշված ֆայլերը?",
            icon: "warning",
            buttons: ["Ոչ", "Այո"]
        }).then(confirm => {
            if (confirm) {
                selectedItems?.length && selectedItems.forEach(item => {
                    if (item?.type === 'FOLDER') {
                        this.props.DeleteFolder(item?.id, pathList)
                    } else {
                        this.props.DeleteGalleryImage(item?.id, pathList);
                    }
                });
            }
        });
    };

    openFolder(folderData) {
        this.setState({
            folderList: [...this.state.folderList, folderData],
            searchValue: '',
        });
        history.push({
            pathname: '/media',
            search: `?currentFolder=${folderData?.name}`
        })
    }

    changeFolder = (id) => {
        const {folderList} = this.state;
        if (id === null) {
            this.setState({
                folderList: [],
                searchValue: '',
            })
            history.push({
                pathname: '/media',
            })
        } else {
            const newList = [];
            folderList.some(f => {
                newList.push(f);
                return f.id === id
            });
            this.setState({
                folderList: newList,
                searchValue: '',
            });
            history.push({
                pathname: '/media',
                search: `?currentFolder=${newList?.[0]?.name}`
            })
        }
    };

    onDropToFolder(folder) {
        //console.log('on-drop')
        const {draggableFile} = this.state;
        if (draggableFile && draggableFile.id !== folder.id) {
            const reqData = {
                parentPath: folder.path || '/',
                name: draggableFile.name,
                parent: folder.id || null,
            };
            // console.log("reqData", reqData);
            this.props.UpdateFile(draggableFile.id, reqData, draggableFile.type).then(() => {
                this.props.GetMedia();
            }).catch((error) => {
                if (error && error.response && error.response.status === 409) {

                } else {

                }
            })
        }
    }

    dragFile(draggableFile) {
        this.setState({
            draggableFile,
        })
    }

    openPreview(item) {
        this.setState({
            previewItem: item,
            previewModalOpen: true
        })
    }

    closePreview() {
        this.setState({
            previewItem: null,
            previewModalOpen: false
        })
    }

    toggleSearchBtn(e) {
        e.preventDefault();
        this.setState({
            searchValue: ''
        })
    };

    selectCard(e, media) {
        const {selectedItems} = this.state;

        if (e.ctrlKey) {
            let medias = [...selectedItems];

            if (!selectedItems?.find(item => item?.id === media?.id)) {
                medias.push({
                    id: media?.id,
                    type: media?.type
                });
            } else {
                medias = selectedItems.filter(item => item?.id !== media?.id);
            }

            this.setState({
                selectedItems: [...medias]
            })
        }
    }

    render() {
        const {mediaItems, leftSideMenuOpen, mediaView} = this.props;
        const {searchValue, folderList, previewModalOpen, previewItem, selectedItems} = this.state;

        let itemsList = mediaItems ? mediaItems.itemsList : [];
        let currentFolder = null;
        let pathList = [];
        if (folderList && folderList.length) {
            currentFolder = folderList[folderList.length - 1];
            pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
            itemsList = getFolderData(itemsList, pathList);
        }
        if (searchValue) {
            itemsList = itemsList.filter((item => item?.name?.toLowerCase().includes(searchValue?.toLowerCase())))
        }
        return <PageWrapper
            mediaPageTitle={<h1 className={`page-title media-title ${leftSideMenuOpen ? 'small-title' : ''}`}>
                <div>
                            <span className={'path-item'}
                                  onClick={() => this.changeFolder(null)}
                                  onDragOver={(e) => currentFolder && e.preventDefault()}
                                  onDrop={() => currentFolder && this.onDropToFolder({})}
                            >
                        Ֆայլեր
                    </span>
                    {
                        folderList && !!folderList.length && folderList.map((folder, index) => {
                            const notLastFolderInPath = folderList.length !== index + 1;
                            return <>
                                <span> / </span>
                                <span className={'path-item'}
                                      onClick={() => notLastFolderInPath && this.changeFolder(folder.id)}
                                      onDragOver={(e) => notLastFolderInPath && e.preventDefault()}
                                      onDrop={() => notLastFolderInPath && this.onDropToFolder(folder)}>
                                        {folder.name}
                                    </span>
                            </>
                        })
                    }
                </div>
                <div>
                    <div className={`search_box`}>
                        <button type="button" className=" search_btn" onClick={this.toggleSearchBtn}>
                            <SearchIcon title={''}/>
                        </button>
                        <InputGroup
                            inputType={"input"}
                            type={'text'}
                            value={searchValue}
                            placeholder={'Որոնել'}
                            name={'searchValue'}
                            onChange={this.getSearchValue}>
                        </InputGroup>

                    </div>
                </div>
            </h1>}>
            <div className={`admin-media`}>
                <section>
                    <div className={'media-action-buttons'}>
                        <div className={'add-buttons-wrapper'}>
                            <AddMediaFolder folderList={folderList}/>
                            <MediaUploaderWrapper currentFolder={currentFolder} withModal/>
                        </div>
                        <div className={"right-wrapper"}>
                            {!!selectedItems.length &&
                            <button className={'delete-btn'}>
                                <DeleteIcon className={'delete-icon'} title={''} onClick={this.deleteMediaList}/>
                            </button>}
                            <div className={"toggle-track-view"}>
                                <LinkButton
                                    className={`list-view-button ${mediaView ? 'selected' : ''}`}
                                    title={<img src={listIcon} alt=""/>}
                                    cb={() => toggleMediaView(true)}/>
                                <LinkButton
                                    className={`list-view-button ${!mediaView ? 'selected' : ''}`}
                                    title={<img src={gridIcon} alt=""/>}
                                    cb={() => toggleMediaView(false)}/>
                            </div>
                        </div>
                    </div>

                    <div className={`media-wrapper ${mediaView ? "list-view" : ""}`}>
                        {mediaView && <div className={"list-header"}>
                            <span>Name</span>
                            <span></span>
                            <span>Size</span>
                            <span>Type</span>
                            <span>Date added</span>
                        </div>}
                        {itemsList && !!itemsList.length && itemsList.map((item) => {
                            return item.type === "FOLDER"
                                ? <MediaFolderCard key={item.id}
                                                   deleteMedia={this.deleteFolder}
                                                   openFolder={this.openFolder}
                                                   withEdit={true}
                                                   draggable={true}
                                                   dragFile={this.dragFile}
                                                   onDrop={this.onDropToFolder}
                                                   folderList={folderList}
                                                   item={item}
                                                   listView={mediaView}
                                                   selectCard={this.selectCard}
                                                   selectedItems={selectedItems}/>
                                :
                                <MediaFileCard key={item.id}
                                               deleteMedia={this.deleteFile}
                                               inModal={false}
                                               withEdit={true}
                                               withCopy={true}
                                               draggable={true}
                                               onDoubleClick={this.openPreview}
                                               dragFile={this.dragFile}
                                               folderList={folderList}
                                               item={item}
                                               listView={mediaView}
                                               selectCard={this.selectCard}
                                               selectedItems={selectedItems}/>
                        })}
                    </div>
                </section>
                <PreviewModal visible={previewModalOpen}
                              data={previewItem}
                              closeModal={this.closePreview}/>
            </div>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetMedia,
    AddFolder,
    UpdateFile,
    DeleteGalleryImage,
    DeleteFolder
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Media));
