import {FEATURE_CONSTS} from "../constants";

export const initialState = {
    featureList: [],
    featureById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FEATURE_CONSTS.GET_FEATURES:
            return {
                ...state,
                featureList: action.payload || [],
            };
        case FEATURE_CONSTS.CREATE_FEATURE:
            return {
                ...state,
                featureList: [action.payload].concat(state.featureList)
            };
        case FEATURE_CONSTS.DELETE_FEATURE_BY_ID:
            return {
                ...state,
                featureList: state.featureList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
