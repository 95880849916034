export const StaticTextFields = {
    emptyViews: {
        pageName: 'Empty Views',
        list: [
            {
                label: "Blog empty view text",
                key: "blog_empty_view"
            },
        ]
    },
    header: {
        pageName: 'Navigation Bar',
        list: [
            {
                label: "Navigation - work",
                key: "nav_item_work"
            },
            {
                label: "Navigation - team",
                key: "nav_item_team"
            },
            {
                label: "Navigation - contact",
                key: "nav_item_contact"
            },
            {
                label: "Navigation - partners",
                key: "nav_item_partners"
            },
            {
                label: "Navigation - blog",
                key: "nav_item_blog"
            },
            {
                label: "Navigation - about_us",
                key: "nav_item_about_us"
            },
            {
                label: "Navigation - services",
                key: "nav_item_services"
            },
            {
                label: "Navigation - home",
                key: "nav_item_home"
            },
            {
                label: "Navigation - language",
                key: "nav_item_language"
            }
        ]
    },
    buttons: {
        pageName: 'Buttons',
        list: [
            {
                label: "Buttons - about us",
                key: "btn_about_us"
            },
            {
                label: "Buttons - team",
                key: "btn_team"
            },
            {
                label: "Buttons - see more",
                key: "btn_see_more"
            },
            {
                label: "Buttons - btn send",
                key: "btn_send"
            },
            {
                label: "Buttons - contacts",
                key: "btn_contacts"
            },
            {
                label: "Buttons - more",
                key: "btn_more"
            },
        ]
    },
    select: {
        pageName: 'Select',
        list: [
            {
                label: "Inputs - select option all",
                key: "select_option_all"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - services title",
                key: "homepage_services_title"
            },
            {
                label: "Homepage - about details link",
                key: "homepage_about_details_link"
            },
            {
                label: "Homepage - work title",
                key: "homepage_work_title"
            },
            {
                label: "Homepage - features title",
                key: "homepage_features_title"
            },
            {
                label: "Homepage - features description",
                key: "homepage_features_description"
            },
            {
                label: "Homepage - features text",
                key: "homepage_features_text"
            },
            {
                label: "Homepage - subscribe title",
                key: "homepage_subscribe_title"
            },
            {
                label: "Homepage - subscribe description",
                key: "homepage_subscribe_description"
            },
            {
                label: "Homepage - subscribe placeholder",
                key: "homepage_subscribe_placeholder"
            },
            {
                label: "Homepage - partners title",
                key: "homepage_partners_title"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - security",
                key: "footer_security"
            },
            {
                label: "Footer - privacy",
                key: "footer_privacy"
            },
            {
                label: "Footer - terms conditions",
                key: "footer_terms_conditions"
            },
            {
                label: "Footer - company",
                key: "footer_company"
            },
            {
                label: "Footer - partners",
                key: "footer_partners"
            },
            {
                label: "Footer - download app",
                key: "footer_section_download_app"
            },
            {
                label: "Footer - copyright text",
                key: "footer_copyright_text"
            },
            {
                label: "Footer - project developed by text",
                key: "footer_developed_by_text"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - Page workers",
                key: "about_page_workers"
            },
            {
                label: "About - Page partners",
                key: "about_page_partners"
            },
            {
                label: "About - Page bookkeeping",
                key: "about_page_bookkeeping"
            },
            {
                label: "About - Page businessPlan",
                key: "about_page_businessPlan"
            },
        ]
    },
    callModel: {
        pageName: 'Call Model',
        list: [
            {
                label: "CallModel-Title",
                key: "call_model_title"
            },
            {
                label: "FullName - Placeholder",
                key: "full_name_placeholder"
            },
            {
                label: "Email - Placeholder",
                key: "email_placeholder"
            },
            {
                label: "PhoneNumber_Placeholder",
                key: "number_placeholder"
            },
            {
                label: "ServiceType_Placeholder",
                key: "service_placeholder"
            },
            {
                label: "Call_Shortly_text",
                key: "call_shortly_text"
            },
            {
                label: "Time_text",
                key:"choose_time_text"
            },
            {
                label: "Financial_Type",
                key: "financial_text"
            },
            {
                label: "Business_Type",
                key: "business_text"
            },
            {
                label: "Accounting_Type",
                key: "accounting_text"
            },
            {
                label: "Jurisprudence_Type",
                key: "jurisprudence_text"
            },
            {
                label: "Send_Title",
                key: "send_text"
            },
            {
                label: "CallTime_Placeholder",
                key: "call_time_picker_placeholder"
            },
            {
                label: "Success_Title",
                key: "success_title_text"
            },
            {
                label: "Error_Title",
                key: "error_title_text"
            }
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Page email",
                key: "contacts_page_email"
            },
            {
                label: "About - Page address",
                key: "contacts_page_address"
            },
            {
                label: "About - Page work_time",
                key: "contacts_page_work_time"
            },
            {
                label: "About - Page work time interval",
                key: "contacts_page_work_time_interval"
            },
            {
                label: "About - Page phone",
                key: "contacts_page_phone"
            },
            {
                label: "About - Page placeholder firstName",
                key: "contacts_page_placeholder_firstName"
            },
            {
                label: "About - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "About - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "About - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "About - Page placeholder message",
                key: "contacts_page_placeholder_message"
            }
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Categories all",
                key: "categories_all"
            }
        ]
    },
}