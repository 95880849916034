import {RESERVATION_CONST} from "../constants";


export const initialState = {
    callModelItems:[]
}

export default (state = initialState,action) => {
    switch (action.type){
        case RESERVATION_CONST.GET_CALL_DATA :
            return {
                ...state,
                callModelItems:action.payload
            }
        case RESERVATION_CONST.DELETE_CALL_DATA:
            return {
                ...state,
                callModelItems: state.callModelItems.filter(items => items.id !== action.payload)
            };

        default:
            return state;    }
}