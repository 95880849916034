// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetBlogPage,
    UpdateBlogPage,
    GetBlogList,
    DeleteBlogItem
} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";
import ItemsList from "../../components/ItemsList";
import PageHeader from "../../components/pageContentViews/pageHeader";

class Blog extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'metaTitle',
            'metaDescription',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                backgroundImage: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setBlogData = this.setBlogData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateBlog = this.updateBlog.bind(this);

    }

    async componentDidMount() {
        await this.props.GetBlogPage();
        const {blogData} = this.props;
        this.setBlogData(blogData);
        this.props.GetBlogList();
    }

    setBlogData(service) {
        const {fieldsData} = this.state;
        const translationsData = {};

        if (service) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = service.translations.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    metaTitle: trItem?.metaTitle || '',
                    metaDescription: trItem?.metaDescription || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            backgroundImage: service?.backgroundImage,
        };
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.backgroundImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('backgroundImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('backgroundImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                backgroundImage: mediaArray[0],
            },
            errors: {
                ...errors,
                backgroundImage: false
            }
        })
    }

    async updateBlog() {
        await this.setState({
            requestLoading: true
        });
        const {translations, backgroundImage,} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!backgroundImage) {
            errors.backgroundImage = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateBlogPage(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setBlogData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getListInfo(data) {
        let {mainLanguage} = this.props;
        return data?.map((item) => {
            const {id, translations} = item;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id,
                values: [
                    title ?? '-',
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteBlogItem, 'Բլոգը', id)
            }
        });
    }

    render() {
        const {blogList} = this.props;
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};
        const itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0],
            actions: ["edit","delete"],
        };
        const blogListInfo = this.getListInfo(blogList?.itemsList);
        return <PageWrapper pageTitle={'Բլոգ'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.backgroundImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.backgroundImage && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.backgroundImage?.path)}/>
                </div>}

                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Մետա Անվանում"
                                placeholder="Մետա Անվանում"
                                name="metaTitle"
                                value={currentData.metaTitle}
                                initValue={initData.metaTitle}
                                error={errors['metaTitle' + languageTab]}
                                maxLength={100}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Մետա Նկարագրություն"
                                placeholder="Մետա Նկարագրություն"
                                name="metaDescription"
                                value={currentData.metaDescription}
                                initValue={initData.metaDescription}
                                error={errors['metaDescription' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateBlog}/>
                </div>

                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={"/blog/add"}/>
                <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                           itemListInfo={blogListInfo}
                           loading={requestLoading}
                           editLink={"/blog/edit"}/>
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetBlogPage,
    UpdateBlogPage,
    GetBlogList,
    DeleteBlogItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog)
