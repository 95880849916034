import {AUTH_CONSTS} from "../constants";

export const initialState = {
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    isAdmin: false,
    scope: null,
    accessPages: [],
    user: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_CONSTS.LOG_IN:
        case AUTH_CONSTS.REFRESH_TOKEN:
            return {
                ...state,
                isLoggedIn: true,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
                isAdmin: action.payload.scope === "access:admin",
                scope: action.payload.scope
            };
        case AUTH_CONSTS.SET_ACCESS_PAGES:
            return {
                ...state,
                user: action.payload,
                accessPages: action.payload?.accessPages ?? []
            };

        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
