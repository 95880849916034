// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import {Switch} from "antd";

//import styles
import '../../assets/styles/containerStyles/slider-add-edit.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {CreateFeature, UpdateFeatureById} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import isURL from "validator/es/lib/isURL";

class AddEditFeatures extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                url: '',
                pageId: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditFeature = this.addEditFeature.bind(this);

    }

    async componentDidMount() {
        const {isEditing, fieldsData} = this.state;

        this.customPagesOptions = [];
        Array.isArray(this.props.customPagesList) && this.props.customPagesList.forEach(item => {
            const title = item?.translations?.find(tr => tr.language === this.props.mainLanguage)?.title || '';
            this.customPagesOptions.push({
                name: title,
                id: item?.id,
            })
        });

        if (isEditing) {
            const translationsData = {};
            const {featureList} = this.props;
            const editingData = featureList.find(feature => feature.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.language === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                url: editingData?.url || '',
                pageId: editingData?.pageId || '',
                isHidden: editingData?.isHidden,
            }
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }




    async addEditFeature() {
        await this.setState({
            requestLoading: true
        });
        const {translations, url, isHidden, pageId} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (url && !isURL(url)) {
            errors.url = true;
            result = false;
        }
        // if (!pageId) {
        //     errors.pageId = true
        // }
        if (result) {

            if (!this.state.isEditing) {
                const reqData = {
                    isHidden,
                    translations: getTranslationData(translations)
                };

                if (url) reqData.url = url;
                if (pageId) reqData.pageId = pageId;

                this.props.CreateFeature(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {

                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateFeatureById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Հատկություն' : 'Փոփոխել Հատկությունը'}>
            <section className="slider-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>

                {/*<InputGroup inputType="input"*/}
                {/*            type="text"*/}
                {/*            label="Հղում"*/}
                {/*            placeholder="Հղում"*/}
                {/*            name="url"*/}
                {/*            value={fieldsData?.url}*/}
                {/*            initValue={this.currentData?.url}*/}
                {/*            error={errors?.url}*/}
                {/*            maxLength={100}*/}
                {/*            onChange={this.getInputValues}/>*/}
                <InputGroup inputType="selectCustom"
                            name="pageId"
                            label={"Դինամիկ էջ"}
                            value={fieldsData.pageId}
                            initValue={this.currentData?.pageId}
                    // withClear={true}
                    //         required={true}
                            error={errors['pageId']}
                            placeholder={'Դինամիկ էջ'}
                            onChange={this.getInputValues}
                            options={this.customPagesOptions}
                />

                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className="slider-wrapper">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Նկարագրություն"
                                placeholder="Նկարագրություն"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditFeature}/>
                </div>

            </section>

        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateFeature,
    UpdateFeatureById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditFeatures)
