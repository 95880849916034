import React from 'react';
import {connect} from 'react-redux';
import {Modal} from "antd";

//import components
import {LinkButton} from "../../buttons/buttons";
import MediaUploader from './MediaUploader';
import newFileIcon from '../../../assets/images/file-manager/new_file.svg';
//import utils
import {mapStateToProps} from "../../../redux/mapStateToProps";
import {
    cancelAddMedia,
    getMedias,
    onDropFile,
    onUploadClick,
    triggerFileUpload,
    uploadMedias,
    onUploadProgress
} from "./functions/action-functions";
import {UploadFile, GetMedia} from "../../../redux/actions";

class MediaUploaderWrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            uploadProgress: 0,

            addingFiles: [],
            filesIsChoose: false,
        };
        this.cancelAddMedia = cancelAddMedia.bind(this);
        this.inputElement = React.createRef();
        this.onUploadClick = onUploadClick.bind(this);
        this.triggerFileUpload = triggerFileUpload.bind(this);
        this.getMedias = getMedias.bind(this);
        this.onDropFile = onDropFile.bind(this);
        this.uploadMedias = uploadMedias.bind(this);
        this.onUploadProgress = onUploadProgress.bind(this);
    }


    render() {
        const {currentFolder} = this.props;
        const {filesIsChoose, addingFiles, uploading, uploadProgress} = this.state;
        return (
            <div className={"file-uploader "}>
                <LinkButton cb={this.triggerFileUpload}
                            title={<><img src={newFileIcon} alt=""/>Ավելացնել Ֆայլ</>}/>
                <input
                    style={{display: 'none'}}
                    type="file"
                    ref={this.inputElement}
                    multiple={true}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}
                />
                <Modal
                    visible={filesIsChoose}
                    wrapClassName={'custom-modal'}
                    onCancel={this.cancelAddMedia}>
                    <div className={'media-uploader-wrapper-modal'}>

                        <MediaUploader addingFiles={addingFiles}
                                       uploading={uploading}
                                       uploadProgress={uploadProgress}
                                       getMedias={this.getMedias}
                                       uploadMedias={() => this.uploadMedias(currentFolder)}
                                       cacnel={this.cancelAddMedia}/>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapDispatchToProps = {
    UploadFile,
    GetMedia
};
export default connect(mapStateToProps, mapDispatchToProps)(MediaUploaderWrapper);
