import {request, _urlFeatures} from "../api";
import {FEATURE_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetFeatures = () => {
    const requestData = {
        url: _urlFeatures,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: FEATURE_CONSTS.GET_FEATURES,
                    payload: data
                });
            })
    }
};


export const DeleteFeatureById = id => {
    const requestData = {
        url:  `${_urlFeatures}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: FEATURE_CONSTS.DELETE_FEATURE_BY_ID,
                    payload: id
                });
            })
            .catch(() => {})
    }
};

export const CreateFeature = data => {
    const requestData = {
        url: _urlFeatures,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FEATURE_CONSTS.CREATE_FEATURE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Հատկություն հաջողությամբ ստեղծվեց!"
                })
                history.push('/features')
            })
            .catch(() => {
            })
    }
};

export const UpdateFeatureById = (id, data) => {
    const requestData = {
        url: `${_urlFeatures}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: FEATURE_CONSTS.UPDATE_FEATURE_BY_ID,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Սլայդերը հաջողությամբ փոփոխվեց!"
                })
                history.push('/features')
            })
            .catch(() => {
            })
    }
};

