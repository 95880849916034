import {MENU_CONSTS} from "../constants";

export const initialState = {
    menusList: [],
    menuById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case MENU_CONSTS.GET_MENUS:
            return {
                ...state,
                menusList: action.payload || [],
            };
        case MENU_CONSTS.GET_MENU_BY_ID:
            return {
                ...state,
                menuById: action.payload
            };
        case MENU_CONSTS.CREATE_MENU:
            return {
                ...state,
                menusList: [action.payload].concat(state.menusList)
            };
        case MENU_CONSTS.DELETE_MENU_BY_ID:
            return {
                ...state,
                menusList: state.menusList.filter(menu => menu.id !== action.payload)
            };
        default:
            return state;
    }
}
