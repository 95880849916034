import React from "react";
import {Select, Tooltip} from "antd";

import "../../assets/styles/dataDisplay/inputGroup.scss";

export function InputGroup(props) {
    const {
        label, type, inputType, name, placeholder, error, options, autocomplete = "on", initValue = '',
        defValue, step, value, minValue, maxValue, maxLength, autoFocus, resizable, onSearch, regExp, onKeyDown,
        className, showSearch, disabled, mode, integerNumber, number, required,
    } = props;

    function onChange(e) {
        let {name, value} = e.target;
        if (disabled) {
            return;
        }
        // console.log('Values', value)
        if (number && (isNaN(Number(value)) || value?.split('.')?.[1]?.length > 2)) {
            return;
        }
        if (value && integerNumber) {
            value = parseInt(value)
        }
        if ((integerNumber || number)) {
            if (minValue && Number(value) < minValue && value !== '') {
                return;
            }
            if (maxValue && Number(value) > maxValue && value !== '') {
                return;
            }
        } else {
            value = value.trimStart();
        }
        // console.log('Values', initValue, value, initValue.toString() !== value.toString());
        if (regExp && value && !regExp.test(value)) {
            return;
        }
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || initValue?.toString() !== value?.toString()
        });
    }

    function selectChange(value) {
        // console.log('Values', initValue, value,);
        props.onChange({
            name,
            value,
            haveChanges: (!initValue && value) || (initValue && !value)
                || (initValue && value && initValue.toString() !== value.toString())
        });
    }

    return <div className="input-group">

        {label && <label>{label} {required? <span className={'required-badge'}>*</span> :''}</label>}

        {inputType === "input" && type !== "number" && type !== "checkbox" && type !== "password" &&
        <>
            <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                   value={value || ''} defaultValue={defValue} onChange={onChange} autoComplete={autocomplete}
                   autoFocus={autoFocus} onKeyDown={onKeyDown} disabled={!!disabled}
                   className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "password" &&
        <> <input type={type} name={name} placeholder={placeholder} maxLength={maxLength}
                  value={value || ''} defaultValue={defValue} onChange={onChange}
                  autoFocus={autoFocus} autoComplete={autocomplete} disabled={!!disabled}
                  className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>
            {props.children}
        </>}

        {inputType === "input" && type === "number" &&
        <input type={type} name={name} value={value || ''} min={minValue} max={maxValue}
               step={step} onChange={onChange} disabled={!!disabled}
               className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""}`}/>}

        {inputType === "input" && type === "checkbox" &&
        <input type={type} name={name} checked={value} onChange={onChange}/>}

        {inputType === "textarea" &&
        <textarea name={name} placeholder={placeholder} value={value || ''}
                  defaultValue={defValue} onChange={onChange} maxLength={maxLength}
                  className={`${disabled ? "disabled" : ""} ${error ? "invalid" : ""} ${resizable ? "resizable" : ""}`}/>}

        {inputType === "select" &&
        <select value={value || ''} name={name} placeholder={placeholder} onChange={onChange}
                className={`${error ? "invalid" : ""}`}>
            {options && !!options.length && options.map((item, index) => {
                return <option value={item.value} key={index}>{item.title}</option>
            })}
        </select>}

        {inputType === "selectCustom" &&
        <Select
            value={value}
            showSearch={showSearch}
            defaultOpen={false}
            disabled={!!disabled}
            style={{width: '100%'}}
            placeholder={placeholder}
            onPopupScroll={props.onPopupScroll}
            optionFilterProp="children"
            optionLabelProp={'label'}
            mode={mode}
            filterOption={(input, option) => {
                if (props.onPopupScroll) {
                    return true;
                }
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
            onChange={selectChange}
            onSearch={onSearch}
            className={`${className ? className : ''} ${error ? "invalid" : ""}`}
        >
            {props.children}
            {props.withClear && value !== undefined && <Select.Option value={undefined}>
                {typeof props.withClear === 'boolean' ? 'Ջնջել' : props.withClear}
            </Select.Option>}
            {options && !!options.length && options.map((item) => {
                const name = item?.name || item?.title || '';
                return <Select.Option key={item.id} value={item.id} className={'select-item'} label={name}>
                    {props.tooltip ? <Tooltip placement="left" title={name}>
                        {name}
                    </Tooltip> : (item?.imageUrl ? <div className={'with-image-item'}>
                            <img src={item.imageUrl}/> {name}
                        </div>
                        : name
                    )}
                </Select.Option>
            })}
        </Select>}

        {inputType === "wrapper" &&
        <> {props.children}</>
        }
    </div>
}
