// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetCustomPages, DeleteCustomPageById} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";


class CustomPages extends Component {
    componentDidMount() {
        this.props.GetCustomPages();
    }

    render() {
        let {customPagesList, mainLanguage, requestLoading} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [0, 2],
            status: true,
            actions: ["edit", "delete"],
        };
        let itemListInfo = customPagesList?.map((customPage) => {
            const {id, translations, isHidden} = customPage;
            const mainTranslations = translations?.find(item => item.language === mainLanguage);
            const {title} = mainTranslations || {};
            return {
                id,
                values: [
                    title ?? '-',
                ],
                status: !isHidden,
                deleteCb: deleteItem.bind(this, this.props.DeleteCustomPageById, 'Էջը', id)
            }
        });
       return <PageWrapper pageTitle={'Դինամիկ էջեր'}>
           <PageHeader linkTitle={"Ավելացնել"}
                       addingLink={"/customPages/add"}/>
           <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                      itemListInfo={itemListInfo}
                      loading={requestLoading}
                      editLink={"/customPages/edit"}/>
       </PageWrapper>
    }
}

const mapDispatchToProps = {GetCustomPages, DeleteCustomPageById};

export default connect(mapStateToProps, mapDispatchToProps)(CustomPages);
