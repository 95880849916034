import {CATEGORY_CONSTS} from "../constants";

export const initialState = {
    categoriesList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CATEGORY_CONSTS.GET_CATEGORIES:
            return {
                ...state,
                categoriesList: action.payload || [],
            };
        case CATEGORY_CONSTS.CREATE_CATEGORY:
            return {
                ...state,
                categoriesList: [action.payload].concat(state.categoriesList)
            };
        case CATEGORY_CONSTS.DELETE_CATEGORY_BY_ID:
            return {
                ...state,
                categoriesList: state.categoriesList.filter(category => category.id !== action.payload)
            };
        default:
            return state;
    }
}
