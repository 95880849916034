// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {
    GetCategories,
    DeleteCategoryById,
    UpdateCategoryById,
} from "../../redux/actions";
import {deleteItem} from "../../utils/helperFunctions";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";
import ItemsList from "../../components/ItemsList";


class Category extends Component {
    constructor(props) {
        super(props);

        this.deleteCategoryItem = this.deleteCategoryItem.bind(this);
    }

    componentDidMount() {
        this.props.GetCategories();
    }

    deleteCategoryItem(id){
        this.props.DeleteCategoryById(id).then(()=>{
            this.props.GetCategories();
        })
    }

    render() {
        let {categoriesList, requestLoading, mainLanguage} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [],
            actions: ["edit","delete"],
            status: false,
        };
        let itemListInfo = categoriesList?.map((category) => {
            const {id} = category;
            const trData = category?.translations?.find(tr => tr.language === mainLanguage);
            const title = trData?.title || '';
            return {
                id,
                values: [
                    title ?? '-',
                ],
                deleteCb: deleteItem.bind(this, this.deleteCategoryItem, 'կատեգորիան', id)
            }
        });
        return <PageWrapper pageTitle={'Կատեգորիաներ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/categories/add"}/>

            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/categories/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetCategories,
    DeleteCategoryById,
    UpdateCategoryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Category);
