import { initialState as auth } from "../reducers/auth";
import { initialState as utils } from "../reducers/utils";
import { initialState as languages } from "../reducers/languages";
import { initialState as moderators } from "../reducers/moderators";
import { initialState as staticTexts } from "../reducers/staticTexts";
import { initialState as media } from "../reducers/media";
import { initialState as composite } from "../reducers/composite";
import { initialState as category } from "../reducers/category";
import { initialState as blog } from "../reducers/blog";
import { initialState as slider } from "../reducers/slider";
import { initialState as partner } from "../reducers/partner";
import { initialState as customPages } from "../reducers/customPages";
import { initialState as general } from "../reducers/general";
import { initialState as menu } from "../reducers/menu";
import { initialState as subscribers } from "../reducers/subscribers";
import { initialState as about } from "../reducers/about";
import { initialState as services } from "../reducers/services";
import { initialState as works } from "../reducers/works";
import { initialState as members } from "../reducers/members";
import { initialState as membersSections } from "../reducers/membersSections";
import { initialState as features } from "../reducers/features";
import { initialState as reservation } from "../reducers/reservation";


const reducers = {
    auth,
    utils,
    languages,
    moderators,
    staticTexts,
    media,
    composite,
    category,
    blog,
    slider,
    partner,
    customPages,
    general,
    menu,
    subscribers,
    about,
    services,
    works,
    members,
    features,
    reservation,
    membersSections
};

export default state => {
    const sData = {};
    Object.keys(reducers).forEach(selectorKey => {
        Object.keys(reducers[selectorKey]).forEach(key => {
            sData[key] = state[selectorKey][key];
        });
    });
    return sData;
};

