import { request, _urlServices } from "../api";
import { SERVICES_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetServicesData = () => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: data
                });
            })
    }
};

export const UpdateServicesData = (data) => {
    const requestData = {
        url: `${_urlServices}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};

export const CreateServiceItem = data => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.CREATE_SERVICE_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ծառայությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/services')
            })
            .catch(() => {
            })
    }
};

export const UpdateServicesItemData = (slug, data) => {
    const requestData = {
        url: `${_urlServices}/${slug}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: SERVICES_CONSTS.SET_SERVICES_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/services');
                return res.data
            })
    }
};

export const DeleteServiceItem = id => {
    const requestData = {
        url: `${_urlServices}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.DELETE_SERVICE_ITEM,
                    payload: id
                });
            })
    }
};

export const UpdateServicesPositions = items => {
    const requestData = {
        url: _urlServices,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: SERVICES_CONSTS.UPDATE_SERVICES_POSITIONS
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ թարմացվեցին!"
                })
            })
            .catch(() => {
            })
    }
};