import React from "react";

import "../assets/styles/containerStyles/footer.scss";
import {ReactComponent as Logo4Steps} from '../assets/images/4steps.svg';

export function Footer(props) {


    return <div className={`implementation ${props.leftSideMenuOpen ? "show" : ""}`}>
        <div className={'inner'}>
            <span>Իրագործումը՝ </span>
            <a href="https://4steps.am">
                <Logo4Steps title="4steps"/>
            </a>
        </div>
    </div>
}
