import {HOST_MEDIA_URL} from "../redux/api";
import defaultUserLogo from '../assets/images/user_avatar.png';
import defaultLogo from '../assets/images/default_empty_logo.svg';

export function generateUserImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/files${path}` : defaultUserLogo;
}
export function generateImageMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/files${path}` : defaultLogo;
}

export function generateFileMediaUrl(path) {
    if (path?.startsWith('http')){
        return path;
    }
    return path ? `${HOST_MEDIA_URL}/files${path}` : defaultLogo;
}
