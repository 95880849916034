// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {NestableItem} from "../../components/cards/nestableItem";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetServicesData, UpdateServicesData, DeleteServiceItem, UpdateServicesPositions} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";

class Services extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
            'metaTitle',
            'metaDescription',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                backgroundImage: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setServiceData = this.setServiceData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateService = this.updateService.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    async componentDidMount() {
        await this.props.GetServicesData();
        const {servicesData} = this.props;
        this.setServiceData(servicesData)
    }

    getNestableItems(list, parent = null) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.language === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `services/edit/${item.id}`,
                },
                deleteCb: deleteItem.bind(this, this.props.DeleteServiceItem, 'ծառայությունը', item?.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedServiceId = item.id;
        const {servicesData} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~servicesData?.services.findIndex(m => m.id === movedServiceId) &&
            ~positionedItems.findIndex(m => m.id === movedServiceId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdateServicesPositions(positionedItems);
            this.props.GetServicesData();
        }
    }

    setServiceData(service) {
        const {fieldsData} = this.state;
        const translationsData = {};

        if (service) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = service.translations.find(item => item.language === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                    metaTitle: trItem?.metaTitle || '',
                    metaDescription: trItem?.metaDescription || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
            backgroundImage: service?.backgroundImage,
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.backgroundImage?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('backgroundImage', mediaArray[0]?.id)
                : this.updatedDataMap.delete('backgroundImage');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                backgroundImage: mediaArray[0],
            },
            errors: {
                ...errors,
                backgroundImage: false
            }
        })
    }

    async updateService() {
        await this.setState({
            requestLoading: true
        });
        const {translations, backgroundImage,} = this.state.fieldsData;
        const translationRequiredFields = ['title', 'description'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!backgroundImage) {
            errors.backgroundImage = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdateServicesData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setServiceData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {servicesData} = this.props;
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(servicesData?.services);

        return <PageWrapper pageTitle={'Ծառայություններ'}>

            <section className="about">
                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.backgroundImage ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}/>
                </div>
                {fieldsData?.backgroundImage && <div className={'background-image'}>
                    <img src={generateFileMediaUrl(fieldsData?.backgroundImage?.path)}/>
                </div>}

                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Ենթավերնագիր"
                                placeholder="Ենթավերնագիր"
                                name="description"
                                value={currentData.description}
                                initValue={initData.description}
                                error={errors['description' + languageTab]}
                                maxLength={1000}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Մետա Անվանում"
                                placeholder="Մետա Անվանում"
                                name="metaTitle"
                                value={currentData.metaTitle}
                                initValue={initData.metaTitle}
                                error={errors['metaTitle' + languageTab]}
                                maxLength={100}
                                onChange={this.getTranslationValues}/>
                    <InputGroup inputType="textarea"
                                type="text"
                                label="Մետա Նկարագրություն"
                                placeholder="Մետա Նկարագրություն"
                                name="metaDescription"
                                value={currentData.metaDescription}
                                initValue={initData.metaDescription}
                                error={errors['metaDescription' + languageTab]}
                                maxLength={1000}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateService}/>
                </div>

                {/*<label className={'list-label'}>Բաժիններ</label>*/}
                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={"/services/add"}/>
                {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    onChange={this.onPositionChange}
                    renderItem={NestableItem}
                />}
            </section>

            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData,
    DeleteServiceItem,
    UpdateServicesPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Services)
