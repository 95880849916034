import { request, _urlMembers } from "../api";
import { MEMBERS_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetMembersData = () => {
    const requestData = {
        url: _urlMembers,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: MEMBERS_CONSTS.SET_MEMBERS_DATA,
                    payload: data
                });
            })
    }
};

export const UpdateMembersData = (data) => {
    const requestData = {
        url: `${_urlMembers}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEMBERS_CONSTS.SET_MEMBERS_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};

export const CreateMemberItem = data => {
    const requestData = {
        url: _urlMembers,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEMBERS_CONSTS.CREATE_MEMBER_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Թիմակիցը հաջողությամբ ստեղծվեց!"
                })
                history.push('/members')
            })
            .catch(() => {
            })
    }
};

export const UpdateMembersItemData = (slug, data) => {
    const requestData = {
        url: `${_urlMembers}/${slug}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MEMBERS_CONSTS.SET_MEMBERS_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/members');
                return res.data
            })
    }
};

export const DeleteMemberItem = id => {
    const requestData = {
        url: `${_urlMembers}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEMBERS_CONSTS.DELETE_MEMBER_ITEM,
                    payload: id
                });
            })
    }
};
export const UpdateMembersPositions = items => {
    const requestData = {
        url: _urlMembers,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MEMBERS_CONSTS.UPDATE_MEMBERS_POSITIONS
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};