import {CUSTOM_PAGE_CONSTS} from "../constants";

export const initialState = {
    customPagesList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CUSTOM_PAGE_CONSTS.GET_CUSTOM_PAGES:
            return {
                ...state,
                customPagesList: action.payload || [],
            };
        case CUSTOM_PAGE_CONSTS.CREATE_CUSTOM_PAGE:
            return {
                ...state,
                customPagesList: [action.payload].concat(state.customPagesList)
            };
        case CUSTOM_PAGE_CONSTS.UPDATE_CUSTOM_PAGE:
            return {
                ...state,
                customPagesList: state.customPagesList.map(item => item.id === action.payload.id
                    ? action.payload : item)
            };
        case CUSTOM_PAGE_CONSTS.DELETE_CUSTOM_PAGE_BY_ID:
            return {
                ...state,
                customPagesList: state.customPagesList.filter(item => item.id !== action.payload)
            };
        default:
            return state;
    }
}
