import {request, _urlBlog, _urlServices} from "../api";
import {BLOG_CONSTS, SERVICES_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {LIMIT_GET_BLOG} from "../../constants/constLimitCounts";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";
import {CreateServiceItem} from "./servicesActions";

export const GetBlogPage = () => {
    const requestData = {
        url: `${_urlBlog}/page`,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: BLOG_CONSTS.SET_BLOG_PAGE,
                    payload: data
                });
            })
    }
};


export const UpdateBlogPage = (data) => {
    const requestData = {
        url: `${_urlBlog}/page`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BLOG_CONSTS.SET_BLOG_PAGE,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};

export const GetBlogList = ({
                                reset = true,
                                offset = 0,
                                category,
                                limit = LIMIT_GET_BLOG,
                            } = {}) => {
    let url = `${_urlBlog}?offset=${offset}&limit=${limit}`;
    category && (url += `&category=${category}`);
    const requestData = {
        url,
        token: true,
        method: "GET",
    };

    return dispatch => {
        reset && dispatch({type: UTIL_CONSTS.START_LOADING});
        return request(requestData)
            .then(({data}) => {
                data && dispatch({
                    type: BLOG_CONSTS.SET_BLOG_LIST,
                    payload: {
                        data,
                        category,
                        reset,
                        hasMore: data.length === limit,
                    }
                });
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING
                })
            })
    }
};

export const GetProductById = (id) => {
    const requestData = {
        url: `${_urlBlog}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: BLOG_CONSTS.GET_BLOG_BY_ID,
                    payload: res.data
                })
            })
            .catch(() => {
            })
    }
};

export const CreateBlogItem = (data) => {
    const requestData = {
        url: _urlBlog,
        token: true,
        method: "POST",
        data,
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                notificationSuccess({
                    description: "Բլոգը հաջողությամբ ստեղծվեց!"
                })
                history.push('/dashboard')
            }).catch((error) => {
                // if(error.status === 409){
                //     swal({
                //         title: 'Զգուշացում',
                //         text: `Ալագը արդեն օգտագործվել է`,
                //         icon: "warning",
                //         button: 'Լավ'
                //     });
                // }
            })
    }
};

export const UpdateBlogItemData = (id, data) => {
    const requestData = {
        url: `${_urlBlog}/blog/${id}`,
        method: "PATCH",
        token: true,
        data,
    };

    return (dispatch) => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOG_CONSTS.UPDATE_BLOG_ITEM,
                    payload: data
                });
                notificationSuccess({
                    description: "Բլոգը հաջողությամբ փոփոխվեց!"
                })
                history.push({
                    pathname: '/dashboard',
                })
            }).catch((error) => {
                if(error.status === 409){
                    // swal({
                    //     title: 'Զգուշացում',
                    //     text: `Ալագը արդեն օգտագործվել է`,
                    //     icon: "warning",
                    //     button: 'Լավ'
                    // });
                }
            })
    }
};

export const DeleteBlogItem = (id) => {
    const requestData = {
        url: `${_urlBlog}/blog/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: BLOG_CONSTS.DELETE_BLOG_ITEM,
                    payload: id
                });
                notificationSuccess({
                    description: "Բլոգը հաջողությամբ ջնջվեց!"
                })

            })
    }
};
