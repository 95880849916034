import {UTIL_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    requestLoading: false,
    leftSideMenuOpen: window.innerWidth > 600,
    pageOffset:{
        pageName:'',
        offset:0
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UTIL_CONSTS.START_LOADING:
            return {
                ...state,
                requestLoading: true
            };
        case UTIL_CONSTS.END_LOADING:
            return {
                ...state,
                requestLoading: false
            };
        case UTIL_CONSTS.TOGGLE_LEFT_SIDE_MENU:
            return {
                ...state,
                leftSideMenuOpen: !state.leftSideMenuOpen
            };
        case UTIL_CONSTS.SET_PAGE_OFFSET:
            return {
                ...state,
                pageOffset:action.payload,
            };
        case AUTH_CONSTS.LOG_OUT:
            return {
                ...state,
                ...initialState
           };
        default:
            return state;
    }
}
