import { PARTNER_CONSTS} from "../constants";

export const initialState = {
    partnersData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case PARTNER_CONSTS.SET_PARTNERS_DATA:
            return {
                ...state,
                partnersData: action.payload ,
            };
        case PARTNER_CONSTS.DELETE_PARTNER_ITEM:
            return {
                ...state,
                partnersData: {
                    ...state.partnersData,
                    partners: state.partnersData.partners.filter(item => item.id !== action.payload)
                } ,
            };

        default:
            return state;
    }
}
