// Import packages
import React from "react";
import { LoadingOutlined } from '@ant-design/icons';
import {useLocation} from "react-router-dom";
// Import utils
import {history} from "../../configs/history";

// Import styles
import "../../assets/styles/dataDisplay/buttons.scss";
import {setPageOffset} from "../../redux/actions";

const spinIcon = <LoadingOutlined style={{fontSize: 16, color: "#fff"}}/>;

export function LinkButton(props) {
    const {title, className, link, cb, loading, disabled, loadingWithTitle, forwardRef, style, getScrollPosition} = props;
    let location = useLocation();

    function onClick() {
        const offset = getScrollPosition?.();
        if (offset) {
            const pageName = location.pathname.split('/')?.[1];
            setPageOffset({
                offset,
                pageName,
            })
        }
        if (link) {
            history.push(link);
        } else {
            if (!loading && cb) {
                cb();
            }
        }
    }

    return <button className={`link-button ${className ? className : ''}`}
                   ref={forwardRef}
                   style={style}
                   disabled={disabled || false}
                   onClick={onClick}>
        {loading ?
            <div className={`flex-row ${loadingWithTitle ? 'loadingWithTitle' : ''}`}>
                {loadingWithTitle && title}
                <LoadingOutlined />
            </div> :
            <span className="title">{title}</span>}
    </button>
}
