import { request, _urlWorks } from "../api";
import { WORKS_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetWorksData = () => {
    const requestData = {
        url: _urlWorks,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: WORKS_CONSTS.SET_WORKS_DATA,
                    payload: data
                });
            })
    }
};


export const UpdateWorksData = (data) => {
    const requestData = {
        url: `${_urlWorks}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: WORKS_CONSTS.SET_WORKS_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};

export const CreateWorkItem = data => {
    const requestData = {
        url: _urlWorks,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: WORKS_CONSTS.CREATE_WORK_ITEM,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Ծառայությունը հաջողությամբ ստեղծվեց!"
                })
                history.push('/works')
            })
            .catch(() => {
            })
    }
};

export const UpdateWorksItemData = (slug, data) => {
    const requestData = {
        url: `${_urlWorks}/${slug}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: WORKS_CONSTS.SET_WORKS_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                })
                history.push('/works');
                return res.data
            })
    }
};

export const DeleteWorkItem = id => {
    const requestData = {
        url: `${_urlWorks}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: WORKS_CONSTS.DELETE_WORK_ITEM,
                    payload: id
                });
            })
    }
};

export const UpdateWorksPositions = items => {
    const requestData = {
        url: _urlWorks,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: WORKS_CONSTS.UPDATE_WORKS_POSITIONS
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ թարմացվեց!"
                })
            })
            .catch(() => {
            })
    }
};