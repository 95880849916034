export const ACCESS_PAGES = {
    // moderators: {
    //     key: 'MODERATORS',
    //     name: 'Մոդերատորներ',
    //     icon: 'supervised_user_circle',
    //     crud: false,
    //     isHidden: true,
    // },
    blog: {
        key: 'BLOGS',
        name: 'Բլոգ',
        icon: 'import_contacts',
        crud: true,
        isHidden: true,
    },
    categories: {
        key: 'CATEGORIES',
        name: 'Կատեգորիաներ',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    media: {
        key: 'FILES',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    slider: {
        key: 'SLIDER',
        name: 'Սլայդեր',
        icon: 'amp_stories',
        crud: true,
        isHidden: false,
    },
    menu: {
        key: 'MENU',
        name: 'Մենյու',
        icon: 'chrome_reader_mode',
        crud: true,
        isHidden: false,
    },
    subscribers: {
        key: 'SUBSCRIBERS',
        name: 'Բաժանորդներ',
        icon: 'supervisor_account',
        crud: true,
        isHidden: false,
    },
    about: {
        key: 'ABOUT',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    services: {
        key: 'SERVICES',
        name: 'Ծառայություններ',
        icon: 'settings',
        crud: true,
        isHidden: false,
    },
    works: {
        key: 'WORKS',
        name: 'Աշխատանքներ',
        icon: 'work',
        crud: true,
        isHidden: false,
    },
    partners: {
        key: 'PARTNERS',
        name: 'Գործընկերներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    members: {
        key: 'MEMBERS',
        name: 'Թիմ',
        icon: 'group',
        crud: true,
        isHidden: false,
    },
    features: {
        key: 'FEATURES',
        name: 'Հատկություններ',
        icon: 'star',
        crud: true,
        isHidden: false,
    },
    customPages: {
        key: 'CUSTOM_PAGES',
        name: 'Դինամիկ էջեր',
        icon: 'web',
        crud: true,
        isHidden: false,
    },
    reservations: {
        key: 'RESERVATIONS',
        name: 'Հայտեր',
        icon: 'book_online',
        crud: true,
        isHidden: false,
    },
    languages: {
        key: 'LANGUAGES',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'CONTACTS',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'STATIC_TEXTS',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
