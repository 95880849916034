//media modal
export const MEDIA_MODAL_TYPES = {
    MEDIA_MAIN: 'MEDIA_MAIN',
    MEDIA_ARRAY: 'MEDIA_ARRAY',
};

export const MEDIA_TYPES = {
    FILE: 'FILE',
    FOLDER: 'FOLDER',
};

export const DEFAULT_IMAGE_ID = '5db99765e2019423d6e0df80';
